<template>
<div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
    <div class="container p-3">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body stretch class="bg-primary full-height d-flex wrap register-side text-center align-items-center">
                    <div class="full-width align-self-start">
                        <div class="full-width navbar-brand mb-4 mt-4">
                            <img :src="appLogo()" height="100" alt="Dynamic Studio Logo">
                        </div>
                        <q-separator class="m-0" dark />
                    </div>
                    <div class="full-width pb-4 align-self-end text-white">
                        <h2 class="mt-4 mb-4 text-h4">{{ translate('welcome') }}</h2>
                        <q-btn icon-right="send" color="danger" square no-caps :to="{name: 'Register'}" type="button" class="no-text-decoration bg-brand-color-red icon-right-leftspace">{{ translate('register') }}</q-btn>
                        <q-btn icon-right="login" color="dark" square no-caps :to="{name: 'Login'}" type="button" class="ml-1 no-text-decoration icon-right-leftspace">{{ translate('login') }}</q-btn>
                    </div>
                </q-card>
            </b-col>
            <b-col md="6" class="p-0 bg-white">
                 <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang pl-3 pr-1 mb-4" :style="{position: 'relative', marginTop: '-1rem'}">
                        <div class="text-muted font-weight-bold">{{ translate('selected_language') }}</div>
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <b-form @submit.prevent="resetPassword">
                        <h1 class="text-h4 text-uppercase text-center font-weight-bold text-primary">{{translate('reset_password')}}</h1>
                        <!-- <p class="text-center text-muted">{{ translate('new_password') }}</p> -->

                        <b-input-group class="mb-3">
                            <q-input autocomplete="current-password" light square dense outlined :error-message="translate('password_is_required')" no-error-icon :error="submitted && $v.newUser.password.$error" color="primary" type="password" v-model="newUser.password" :label="translate('password')">
                                <template v-slot:append>
                                    <q-icon name="icon-lock" />
                                </template>
                            </q-input>
                            <div v-if="submitted && $v.newUser.password.$error" class="invalid-feedback">
                                <span v-if="!$v.newUser.password.password">Password is invalid</span>
                            </div>
                        </b-input-group>

                        <b-input-group class="mb-3">
                            <q-input autocomplete="current-password" light square dense outlined :error-message="translate('password_confirmation_is_required')" no-error-icon :error="submitted && $v.newUser.password_confirmation.$error" color="primary" type="password" v-model="newUser.password_confirmation" :label="translate('confirm_password')">
                                <template v-slot:append>
                                    <q-icon name="icon-lock" />
                                </template>
                            </q-input>
                            <div v-if="submitted && $v.newUser.password_confirmation.$error" class="invalid-feedback">
                                <span v-if="!$v.newUser.password_confirmation.password">Confirm Password is invalid</span>
                                <span v-else-if="!$v.newUser.password_confirmation.sameAsPassword">Passwords must match</span>
                            </div>
                        </b-input-group>

                         <div class="text-center">
                            <q-btn icon-right="send" color="info" square no-caps type="submit" class="no-text-decoration icon-right-leftspace">{{ translate('reset_password') }}</q-btn>
                            <div value="no" :submit="submit == 'yes' ? 'submit' : ''">{{ msg }}</div>
                         </div>
                    </b-form>
                    <div v-if="button" class="text-center mt-3">
                        {{translate('password_resetted_login_account')}}
                        <br />
                        <q-btn icon-right="login" :to="{name: 'Login'}" color="dark" square no-caps type="button" class="no-text-decoration icon-right-leftspace">{{ translate('login') }}</q-btn>
                    </div>
                    <p>{{msg}}</p>
                 </q-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import {
    eventBus
} from '../../main'
import {
    Header as AppHeader
} from '@coreui/vue'
import {
    required,
    email,
    minLength,
    sameAs
} from "vuelidate/lib/validators"
import LangNavbarAuth from './LangNavbarAuth'

export default {
    name: 'ResetPassword',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth
    },
    data: function () {
        return {
            newUser: {
                password: null,
                confirmation_password: null
            },
            msg: '',
            submit: 'no',
            button: false,
            submitted: false,
            selected_language: 'ro',
            success: false,
        }
    },
    validations: {
        newUser: {
            password: {
                required
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted: function () {
        eventBus.$on('current_language', (lang) => {
            this.setSelectedLanguage(lang)
        })
    },
    computed: {
        random_background: function () {
            var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
        container_background_image: function () {
            return 'background-image: url(' + this.random_background + ');'
        },
    },
    methods: {
        setSelectedLanguage: function (lang) {
            this.selected_language = lang
        },
        resetPassword: function () {
            this.submitted = true
            this.$v.$touch()

            if (this.$v.$invalid) {
                return
            }
            var params = {
                password: this.newUser.password,
                email: this.$route.query['email'],
                reset_token: this.$route.query['reset_token']
            }
            axios.post(baseUrl + 'auth/reset-password', params)
                .then(response => {
                    var message = ''
                    if (response.data.status) {
                        this.$toasted.success('Your password is reseted', {
                            duration: this.$toasted_duration
                        })
                        if (this.submit === 'no') {
                            this.submit = 'yes'
                            this.button = true
                            localStorage.setItem('makeUser', this.submit)
                        }
                        this.success = true
                        message = 'reset_password_success'
                    } else {
                        message = "reset_password_" + response.data.msg
                        this.success = false

                        this.msg = this.translate(message)
                        this.$toasted.error(this.msg, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    this.$toasted.error(error.response.data.msg, {
                        duration: this.$toasted_duration
                    })
                })
        }
    }
}
</script>
